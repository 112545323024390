import { extendTheme, Table } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

Table.defaultProps = {
  sx: {
    "tbody tr:last-child td": {
      borderBottomWidth: 0
    }
  }
};

const brandColor = "#2761f5";

const colors = {
  brand: {
    default: brandColor,
    "50": "#FFFFFF",
    "100": "#EAF0FE",
    "200": "#B9CCFC",
    "300": "#88A8FA",
    "400": "#5885F7",
    "500": brandColor,
    "600": "#0A46DF",
    "700": "#0837AE",
    "800": "#06277D",
    "900": "#04184C"
  },
  gray: {
    "50": "#f8fafa",
    "75": "#F2F5F7"
  },
  red: {
    "50": "#FEF4F4"
  }
};

export const theme = extendTheme({
  components: {
    Alert: {
      baseStyle: {
        borderRadius: "md"
      }
    },
    Button: {
      sizes: {
        md: {
          fontSize: "sm",
          height: "9"
        }
      }
    },
    Checkbox: {
      defaultProps: {
        colorScheme: "brand"
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: "sm"
      }
    },
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            opacity: 1
          }
        }
      },
      // defaultProps: props => ({
      // 	focusBorderColor: mode("brand.default", "brand.400")(props)
      // }),
      defaultProps: {
        focusBorderColor: "brand.default"
      },
      parts: ["field"],
      sizes: {
        md: {
          field: {
            fontSize: "sm",
            height: "9",
            px: "0.575rem"
          }
        }
      }
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none"
        }
      }
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.default"
      },
      parts: ["field"],
      sizes: {
        md: {
          field: {
            fontSize: "sm",
            h: "9",
            px: "0.35rem"
          }
        }
      }
    },
    Skeleton: {
      baseStyle: {
        borderRadius: "md"
      },
      defaultProps: {
        endColor: "gray.300"
      }
    },
    Table: {
      sizes: {
        sm: {
          fontSize: "sm"
        }
      }
    },
    Text: {
      baseStyle: {
        fontSize: "small"
      }
    }
  },
  fonts: {
    body: "Inter",
    heading: "Inter"
  },
  shadows: {
    outline: `0 0 0 0.175rem ${colors.brand[100]}`
  },
  styles: {
    global: props => ({
      html: {
        fontSize: 15
      },
      body: {
        backgroundColor: mode("white", "gray.900")(props),
        display: "flex",
        flexDirection: "column",
        height: "100vh"
      },
      "#root": {
        height: "100%"
      }
    })
  },
  colors
});
