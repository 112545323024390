import React from "react";
import {
  Alert,
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { MdNotificationsActive } from "react-icons/md";
import { last } from "lodash-es";
import { NavLink, useLocation } from "react-router-dom";
import { When } from "react-if";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useStoreActions, useStoreState } from "store";

const activeStatus = "reminder";

export const Reminders = (): JSX.Element => {
  const location = useLocation();
  const accountState = useStoreState(state => state.account);
  const [ shouldShowHelpers, setShouldShowHelpers ] = React.useState(false);
  const tasksActions = useStoreActions(actions => actions.tasks);
  const tasksState = useStoreState(state => state.tasks);

  const loadMore = React.useCallback(() => {
    if(!accountState.clientId) {
      return;
    }

    const lastTask = last(tasksState[activeStatus].items) as Required<typeof tasksState[typeof activeStatus]["items"][number]>;

    tasksActions.getMany({
      clientId: accountState.clientId,
      status: activeStatus,
      startAfter: {
        reminder: lastTask.reminder
      }
    });
  }, [accountState.clientId, activeStatus, tasksState]);

  const [ loadMoreRef ] = useInfiniteScroll({
    disabled: tasksState[activeStatus].items.length === 0,
    hasNextPage: tasksState[activeStatus].moreItems,
    loading: tasksState.loadingMore,
    onLoadMore: loadMore
  });

  React.useEffect(() => {
    if(!accountState.clientId) {
      return;
    }

    setShouldShowHelpers(false);
    tasksActions.clearTasks(activeStatus);
    tasksActions.setShouldListenerUpdate(false);

    // To avoid flickering
    setTimeout(() => {
      tasksActions.getMany({
        clientId: accountState.clientId as number,
        status: activeStatus
      }).then(() => {
        setShouldShowHelpers(true);
      });
    }, 10);
  }, [accountState.clientId]);

  React.useEffect(() => {
    const realtime = () => {
      if(!accountState.clientId) {
        return;
      }

      type OnSnapshotParams = Parameters<ReturnType<typeof tasksActions["listen"]>["onSnapshot"]>;
      type Snapshot = Parameters<OnSnapshotParams[1]>[0];

      const onSnapshot = (snapshot: Snapshot) => {
        snapshot.docChanges()
          .filter(change => ["added", "modified", "removed"].includes(change.type))
          .forEach(change => {
            tasksActions.update({
              changeType: change.type,
              item: change.doc.data(),
              status: activeStatus
            });
          });

        tasksActions.setShouldListenerUpdate(true);
      };

      const query = tasksActions.listen({
        clientId: accountState.clientId,
        status: activeStatus
      });

      return query.onSnapshot(onSnapshot);
    };

    return realtime();
  }, [accountState]);

  React.useEffect(() => {
    return () => {
      tasksActions.setShouldListenerUpdate(false);
    };
  }, []);

  return (
    <Flex
      alignItems="stretch"
      justifyContent="center"
      width="100%"
    >
      <VStack
        height="100%"
        overflow="hidden"
        spacing="0"
        width="100%"
      >
        <VStack
          height="100%"
          overflowY={tasksState.loading ? "hidden" : "auto"}
          padding={tasksState.error ? "4" : undefined}
          paddingInline="20"
          spacing="0"
          sx={{
            "a.active": {
              backgroundColor: "gray.200"
            }
          }}
          width="100%"
        >
          {
            (
              tasksState.loading ?
                Array(10).fill({}) :
                tasksState[activeStatus].items
            ).map((task: typeof tasksState[typeof activeStatus]["items"][number], index) => (
              <Grid
                _hover={{
                  backgroundColor: "gray.75"
                }}
                as={NavLink}
                borderBottomWidth="thin"
                borderInlineEndWidth="thin"
                borderInlineStartWidth="thin"
                borderColor={tasksState.loading ? "transparent" : "gray.200"}
                columnGap="4"
                key={task.taskId || index}
                opacity={
                  tasksState.loading ?
                    1 - (((index + 1) / 10) - (1 / 10)) :
                    undefined
                }
                padding="4"
                sx={{ pointerEvents: tasksState.loading ? "none" : undefined }}
                templateColumns="1fr .45fr 1fr"
                to={`/reminders/${task.taskId}${location.search}`}
                transitionDuration="200ms"
                width="100%"
              >
                <HStack>
                  <Skeleton
                    isLoaded={tasksState.loading === false}
                    width={tasksState.loading ? "50%" : undefined}
                  >
                    <Text
                      fontWeight="semibold"
                      overflow="hidden"
                      title={task.title}
                    >
                      { task.title || "Loading" }
                    </Text>
                  </Skeleton>
                  <Skeleton isLoaded={tasksState.loading === false} width={tasksState.loading ? "60px" : undefined}>
                    <Text
                      color="gray.500"
                      fontSize=".75rem"
                      fontWeight="semibold"
                    >
                        #{ task.taskId || "Loading" }
                    </Text>
                  </Skeleton>
                </HStack>
                <Skeleton
                  as={HStack}
                  isLoaded={tasksState.loading === false}
                  height="1.4rem"
                  flexShrink={1}
                  justifyContent="flex-start"
                  title={`Reminder: ${dayjs(task.deadline).format("DD MMM, YYYY")}`}
                  width="100%"
                >
                  <Icon as={MdNotificationsActive} color="gray.600"/>
                  <Text fontSize="smaller">
                    {
                      dayjs(task.reminder)
                        .format(
                          dayjs().isSame(dayjs(task.reminder), "year") ?
                            "DD MMM" :
                            "DD MMM 'YY"
                        )
                    }
                  </Text>
                </Skeleton>
                <Skeleton
                  as={Box}
                  isLoaded={tasksState.loading === false}
                  width={tasksState.loading ? "80%" : undefined}
                >
                  <Text
                    color="gray.700"
                    fontSize="smaller"
                    title={task.statusToClient}
                  >
                    { task.statusToClient || "Loading" }
                  </Text>
                </Skeleton>
              </Grid>
            ))
          }
          <When condition={tasksState.error !== null}>
            <Alert
              backgroundColor="red.50"
              borderColor="red.100"
              borderRadius="md"
              borderWidth="thin"
              color="red.600"
              fontSize="sm"
              status="error"
            >
              <Text overflowWrap="anywhere">
                { tasksState.error }
              </Text>
            </Alert>
          </When>
          <When
            condition={
              activeStatus &&
              shouldShowHelpers &&
              tasksState.loading === false &&
              tasksState[activeStatus].items.length === 0
            }
          >
            <Text color="gray.500" padding="4">
              There are no { activeStatus } tasks
            </Text>
          </When>
          {
            (
              tasksState[activeStatus].moreItems &&
              tasksState[activeStatus].items.length >= 20 &&
              tasksState.loading === false
            ) && (
              <Center ref={loadMoreRef} padding="4">
                <Spinner color="brand.500" thickness="3px"/>
              </Center>
            )
          }
        </VStack>
      </VStack>
    </Flex>
  );
};
