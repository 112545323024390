import {
  action,
  Action,
  actionOn,
  ActionOn,
  thunk,
  Thunk
} from "easy-peasy";

import {
  GetActiveParameters,
  GetClosedParameters,
  GetPendingParameters,
  GetRemindersParameters,
  Task
} from "services";
import { Injections } from "store";
import { BaseModel, createBaseModel } from "store/create-base-model";

const statuses = {
  active: 1,
  pending: 2,
  closed: 3,
  reminder: 4
};

export interface Tasks extends BaseModel<Tasks> {
  active: {
    items: Partial<Task>[];
    moreItems: boolean;
  };
  pending: {
    items: Partial<Task>[];
    moreItems: boolean;
  };
  closed: {
    items: Partial<Task>[];
    moreItems: boolean;
  };
  reminder: {
    items: Partial<Task>[];
    moreItems: boolean;
  };
  shouldListenerUpdate: boolean;

  clearTasks: Action<Tasks, keyof typeof statuses>;
  getMany: Thunk<
    Tasks,
    ({ status: "active" } & GetActiveParameters) |
    ({ status: "pending" } & GetPendingParameters) |
    ({ status: "closed" } & GetClosedParameters) |
    ({ status: "reminder" }) & GetRemindersParameters,
    Injections
  >;
  listen: Thunk<
    Tasks,
    {
      clientId: number;
      status: keyof typeof statuses;
    },
    Injections,
    {},
    ReturnType<ReturnType<Injections["firestore"]["collection"]>["endAt"]>
  >;
  onGetMany: ActionOn<Tasks>;
  resetMoreItems: Action<Tasks>;
  setShouldListenerUpdate: Action<Tasks, boolean>;
  update: Action<
    Tasks,
    {
      changeType: "added" | "modified" | "removed";
      item: Partial<Task>;
      status: keyof typeof statuses;
    }
  >;
}

export const tasks: Tasks = {
  ...createBaseModel({ action }),

  active: {
    items: [],
    moreItems: true
  },
  pending: {
    items: [],
    moreItems: true
  },
  closed: {
    items: [],
    moreItems: true
  },
  reminder: {
    items: [],
    moreItems: true
  },
  shouldListenerUpdate: false,

  clearTasks: action((state, payload) => {
    state[payload].items = [];
  }),
  getMany: thunk(async (actions, payload, helpers) => {
    if(payload.startAfter) {
      actions.setLoadingMore(true);
    } else {
      actions.setLoading(true);
    }

    actions.resetMoreItems();
    actions.setError(null);

    try {
      switch(payload.status) {
        case "active":
          return await helpers.injections.services.tasks.getActive(payload);
        case "closed":
          return await helpers.injections.services.tasks.getClosed(payload);
        case "pending":
          return await helpers.injections.services.tasks.getPending(payload);
        case "reminder":
          return await helpers.injections.services.tasks.getReminders(payload);
        default:
          break;
      }
    } catch(error) {
      actions.setError(error.message);
      helpers.fail(error.message);
    } finally {
      if(payload.startAfter) {
        actions.setLoadingMore(false);
      } else {
        actions.setLoading(false);
      }
    }
  }),
  listen: thunk((actions, payload, helpers) => {
    const firestore = helpers.injections.firestore;
    const query = (
      firestore
        .collection("events")
        .doc(payload.clientId.toString())
        .collection("tasksList")
    );

    switch(payload.status) {
      case "active":
        return query
          .where("statusId", "==", 1)
          .orderBy("priorityId", "asc")
          .orderBy("deadline", "asc")
          .orderBy("taskId", "asc");
      case "pending":
        return query
          .where("statusId", "==", 2)
          .orderBy("priorityId", "asc")
          .orderBy("deadline", "desc");
      default:
        return query;
    }
  }),
  onGetMany: actionOn(
    actions => actions.getMany,
    (state, target) => {
      if(target.payload.startAfter) {
        state[target.payload.status].items = [
          ...state[target.payload.status].items,
          ...target.result
        ];

        if(target.result.length === 0) {
          state[target.payload.status].moreItems = false;
        }

        return;
      }

      state[target.payload.status].items = target.result;

      if(target.result.length < 20) {
        state[target.payload.status].moreItems = false;
      }
    }
  ),
  resetMoreItems: action(state => {
    state.active.moreItems = true;
    state.closed.moreItems = true;
    state.pending.moreItems = true;
    state.reminder.moreItems = true;
  }),
  setShouldListenerUpdate: action((state, payload) => {
    state.shouldListenerUpdate = payload;
  }),
  update: action((state, payload) => {
    if(state.shouldListenerUpdate === false) {
      return;
    }

    switch(payload.changeType) {
      case "modified":
        state[payload.status].items = state[payload.status].items.map(item => {
          if(item.id === payload.item.id) {
            return payload.item;
          }

          return item;
        });

        break;
      case "removed":
        state[payload.status].items = state[payload.status].items.filter(item => (
          item.id !== payload.item.id
        ));
        break;
      case "added":
        state[payload.status].items = [
          payload.item,
          ...state[payload.status].items
        ];
        break;
      default:
        break;
    }
  })
};
