import {
  action,
  Action,
  State
} from "easy-peasy";

export interface BaseModel<T extends object = any> {
  error: string | null;
  loading: boolean;
  loadingMore: boolean;

  setError: Action<T, string | null>;
  setLoading: Action<T, boolean>;
  setLoadingMore: Action<T, boolean>;
}

export interface BaseModelOptions {
  action: typeof action;
}

export const createBaseModel = (options: BaseModelOptions): BaseModel => ({
  error: null,
  loading: true,
  loadingMore: false,

  setError: options.action((state: State<BaseModel>, payload) => {
    state.error = payload;
  }),
  setLoading: options.action((state: State<BaseModel>, payload) => {
    state.loading = payload;
  }),
  setLoadingMore: options.action((state: State<BaseModel>, payload) => {
    state.loadingMore = payload;
  })
});

export default createBaseModel;
