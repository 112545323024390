import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Avatar,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Text
} from "@chakra-ui/react";
import {
  FiBell,
  FiList,
  FiPower
} from "react-icons/fi";
import {
  NavLink as RouteLink,
  Switch as RouteSwitch,
  useHistory,
  useLocation
} from "react-router-dom";
import queryString from "query-string";

import {
  Login,
  Tasks,
  Reminders
} from "pages";
import { Route } from "components";

import { useStoreActions, useStoreState } from "store";

import logo from "assets/logo.png";

export const pages = [
  {
    icon: FiList,
    label: "Tasks",
    link: "/tasks",
    requiredQueryParameter: {
      property: "status",
      value: "active"
    }
  },
  {
    icon: FiBell,
    label: "Reminders",
    link: "/reminders"
  }
];

export const Sidebar = (): JSX.Element => {
  const accountActions = useStoreActions(actions => actions.account);
  const accountState = useStoreState(state => state.account);
  const history = useHistory();
  const location = useLocation();
  const logoutAlertCancelRef = React.useRef(null);
  const [ shouldShowLogoutAlert, setShouldShowLogoutAlert ] = React.useState(false);
  const query = React.useMemo(() => (
    queryString.parse(location.search)
  ), [location.search]);

  const logout = React.useCallback(() => {
    accountActions.logout();
    history.replace("/login");
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={shouldShowLogoutAlert}
        leastDestructiveRef={logoutAlertCancelRef}
        onClose={() => {
          //
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody paddingTop="4">
              Are you sure you want to log out?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => { setShouldShowLogoutAlert(false); }}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={logout}
              >
                Log out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex
        borderRightWidth="thin"
        flexDirection="column"
        sx={{
          ".active": {
            backgroundColor: "gray.100"
          },
          ".active > svg": {
            color: "brand.default"
          },
          "a:not(.active) > svg": {
            color: "gray.500"
          }
        }}
        minWidth="250px"
      >
        <Center padding="5">
          <Image
            height="25px"
            src={logo}
          />
        </Center>
        <HStack
          borderRadius="md"
          borderStyle="solid"
          // borderWidth="thin"
          marginInline="4"
          margin="4"
          padding="2"
          spacing="4"
        >
          <Avatar
            shadow="lg"
            size="sm"
            src={accountState.clientPhoto ?? undefined}
          />
          <Text fontSize="smaller" fontWeight="bold">
            { accountState.clientDisplayName }
          </Text>
        </HStack>
        {
          pages.map(page => (
            <Link
              as={RouteLink}
              borderRadius="md"
              fontSize="smaller"
              fontWeight="semibold"
              key={page.link}
              marginTop="2"
              marginInline="4"
              padding="2"
              paddingInline="4"
              to={
                queryString.stringifyUrl({
                  url: page.link,
                  query: (
                    page.requiredQueryParameter ?
                      {
                        [page.requiredQueryParameter.property]: query[page.requiredQueryParameter.property] || page.requiredQueryParameter.value
                      } :
                      undefined
                  )
                })
              }
            >
              <Icon
                __css={{
                  strokeWidth: "3.5px"
                }}
                as={page.icon}
                height="0.95rem"
                marginInlineEnd="0.5rem"
                width="0.95rem"
              />
              { page.label }
            </Link>
          ))
        }
        <Button
          fontSize="smaller"
          marginBottom="4"
          marginTop="auto"
          leftIcon={
            <Icon
              __css={{
                color: "red.500",
                strokeWidth: "3.5px"
              }}
              as={FiPower}
              height="0.95rem"
              width="0.95rem"
            />
          }
          marginInline="4"
          onClick={() => {
            setShouldShowLogoutAlert(true);
          }}
        >
          Logout
        </Button>
      </Flex>
    </>
  );
};

export const Main = (): JSX.Element => {
  return (
    <Flex height="100%">
      <Sidebar/>
      <RouteSwitch>
        <Route path="/tasks/:id?">
          <Tasks/>
        </Route>
        <Route path="/reminders/:id?">
          <Reminders/>
        </Route>
      </RouteSwitch>
    </Flex>
  );
};

export const Root = (): JSX.Element => {
  const accountActions = useStoreActions(actions => actions.account);
  const accountState = useStoreState(state => state.account);

  React.useEffect(() => {
    // We have to wait 500ms before the state
    // gets its value from local storage
    setTimeout(() => {
      accountActions.initialize();
    }, 500);
  }, []);

  if(accountState.initialized) {
    return (
      <RouteSwitch>
        <Route
          exact
          path="/login"
          type="login"
        >
          <Login/>
        </Route>
        <Route path="/" type="private">
          <Main/>
        </Route>
      </RouteSwitch>
    );
  }

  return <></>;
};
