import React from "react";
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import { BiTargetLock } from "react-icons/bi";
import dayjs from "dayjs";
import { FaCheckCircle } from "react-icons/fa";
import { FiPlay } from "react-icons/fi";
import { last } from "lodash-es";
import {
  NavLink,
  useHistory,
  useLocation
} from "react-router-dom";
import queryString from "query-string";
import {
  Switch,
  Case,
  When
} from "react-if";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useStoreActions, useStoreState } from "store";

type Query = Partial<{
  status: typeof statuses[number];
}>

const priorityIdColors = {
  1: "red.500",
  2: "orange.400",
  3: "green.500",
  4: "gray.400"
};

const statuses = ["active", "pending", "closed"] as const;

export const Tasks = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const accountState = useStoreState(state => state.account);
  const activeStatus = React.useMemo(
    () => queryString.parse(location.search).status as Query["status"],
    [location.search]
  );
  const [ shouldShowHelpers, setShouldShowHelpers ] = React.useState(false);
  const tasksActions = useStoreActions(actions => actions.tasks);
  const tasksState = useStoreState(state => state.tasks);

  const loadMore = React.useCallback(() => {
    if(!accountState.clientId || !activeStatus) {
      return;
    }

    const lastTask = last(tasksState[activeStatus].items) as Required<typeof tasksState[typeof activeStatus]["items"][number]>;

    switch(activeStatus) {
      case "active":
        tasksActions.getMany({
          clientId: accountState.clientId,
          status: activeStatus,
          startAfter: {
            taskId: lastTask.taskId as number,
            deadline: lastTask.deadline as string,
            priorityId: lastTask.priorityId as number
          }
        });
        break;
      case "pending":
        tasksActions.getMany({
          clientId: accountState.clientId,
          status: activeStatus,
          startAfter: {
            deadline: lastTask.deadline as string,
            priorityId: lastTask.priorityId as number
          }
        });
        break;
      case "closed":
        tasksActions.getMany({
          clientId: accountState.clientId,
          status: activeStatus,
          startAfter: {
            lastClosedAt: lastTask.lastClosedAt as string
          }
        });
        break;
      default:
        break;
    }

  }, [accountState.clientId, activeStatus, tasksState]);

  const [ loadMoreRef ] = useInfiniteScroll({
    disabled: activeStatus ? tasksState[activeStatus].items.length === 0 : true,
    hasNextPage: activeStatus ? tasksState[activeStatus].moreItems : false,
    loading: activeStatus ? tasksState.loadingMore : false,
    onLoadMore: loadMore
  });

  React.useEffect(() => {
    const query = queryString.parse(history.location.search) as Query;

    if(Boolean(query.status) === false) {
      query.status = "active";
      history.replace({ search: queryString.stringify(query) });
    }
  }, []);

  React.useEffect(() => {
    if(!activeStatus || !accountState.clientId) {
      return;
    }

    setShouldShowHelpers(false);
    tasksActions.clearTasks(activeStatus);
    tasksActions.setShouldListenerUpdate(false);

    // To avoid flickering
    setTimeout(() => {
      tasksActions.getMany({
        clientId: accountState.clientId as number,
        status: activeStatus
      }).then(() => {
        setShouldShowHelpers(true);
      });
    }, 10);
  }, [accountState.clientId, activeStatus]);

  React.useEffect(() => {
    const realtime = () => {
      if(!activeStatus || !accountState.clientId) {
        return;
      }

      type OnSnapshotParams = Parameters<ReturnType<typeof tasksActions["listen"]>["onSnapshot"]>;
      type Snapshot = Parameters<OnSnapshotParams[1]>[0];

      let query;

      const onSnapshot = (snapshot: Snapshot) => {
        snapshot.docChanges()
          .filter(change => ["added", "modified", "removed"].includes(change.type))
          .forEach(change => {
            tasksActions.update({
              changeType: change.type,
              item: change.doc.data(),
              status: activeStatus
            });
          });

        tasksActions.setShouldListenerUpdate(true);
      };

      switch(activeStatus) {
        case "active":
          query = tasksActions.listen({
            clientId: accountState.clientId,
            status: activeStatus
          });

          return query.onSnapshot(onSnapshot);
        case "pending":
          query = tasksActions.listen({
            clientId: accountState.clientId,
            status: activeStatus
          });

          return query.onSnapshot(onSnapshot);
        default:
          break;
      }
    };

    return realtime();
  }, [accountState, activeStatus]);

  React.useEffect(() => {
    return () => {
      tasksActions.setShouldListenerUpdate(false);
    };
  }, []);

  return (
    <Flex
      alignItems="stretch"
      justifyContent="center"
      width="100%"
    >
      <VStack
        height="100%"
        overflow="hidden"
        spacing="0"
        width="100%"
      >
        <Flex
          alignItems="center"
          borderBottomWidth="thin"
          justifyContent="center"
          padding="4"
          width="100%"
        >
          {
            statuses.map((status, index) => (
              <Button
                as={NavLink}
                borderColor={status === activeStatus ? "transparent" : "gray.200"}
                borderRadius="0"
                borderWidth="thin"
                color={status === activeStatus ? "white" : "gray.700"}
                colorScheme={status === activeStatus ? "brand" : undefined}
                fontSize="smaller"
                fontWeight="semibold"
                key={status}
                marginLeft="-1px"
                shadow="none"
                paddingInline="4"
                replace
                size="sm"
                sx={
                  index === 0 ?
                    {
                      borderBottomLeftRadius: "md",
                      borderTopLeftRadius: "md"
                    } : (
                      index === 2 ?
                        {
                          borderBottomRightRadius: "md",
                          borderTopRightRadius: "md"
                        } : undefined
                    )
                }
                textTransform="capitalize"
                to={`/tasks?status=${status}`}
              >
                { status }
              </Button>
            ))
          }
        </Flex>
        <VStack
          height="100%"
          overflowY={tasksState.loading ? "hidden" : "auto"}
          padding={tasksState.error ? "4" : undefined}
          paddingInline="20"
          spacing="0"
          sx={{
            "a.active": {
              backgroundColor: "gray.200"
            }
          }}
          width="100%"
        >
          {
            activeStatus && (
              tasksState.loading ?
                Array(10).fill({}) :
                tasksState[activeStatus].items
            ).map((task: typeof tasksState[typeof activeStatus]["items"][number], index) => (
              <Grid
                _hover={{
                  backgroundColor: "gray.75"
                }}
                as={NavLink}
                borderBottomWidth="thin"
                borderInlineEndWidth="thin"
                borderInlineStartWidth="thin"
                borderColor={tasksState.loading ? "transparent" : "gray.200"}
                columnGap="4"
                key={task.taskId || index}
                opacity={
                  tasksState.loading ?
                    1 - (((index + 1) / 10) - (1 / 10)) :
                    undefined
                }
                padding="4"
                sx={{ pointerEvents: tasksState.loading ? "none" : undefined }}
                templateColumns="auto 1fr .6fr 1fr"
                to={`/tasks/${task.taskId}${location.search}`}
                transitionDuration="200ms"
                width="100%"
              >
                <Box
                  as={Skeleton}
                  backgroundColor={priorityIdColors[task.priorityId as keyof typeof priorityIdColors]}
                  borderRadius="md"
                  isLoaded={tasksState.loading === false}
                  height="100%"
                  width="3px"
                />
                <HStack>
                  <Skeleton
                    isLoaded={tasksState.loading === false}
                    width={tasksState.loading ? "50%" : undefined}
                  >
                    <Text
                      fontStyle={activeStatus === "pending" ? "italic" : undefined}
                      fontWeight="semibold"
                      overflow="hidden"
                      textDecoration={activeStatus === "closed" ? "line-through" : undefined}
                      title={task.title}
                      width="101%"
                    >
                      { task.title || "Loading" }
                    </Text>
                  </Skeleton>
                  <Skeleton isLoaded={tasksState.loading === false} width={tasksState.loading ? "60px" : undefined}>
                    <Text
                      color="gray.500"
                      fontSize=".75rem"
                      fontWeight="semibold"
                    >
                        #{ task.taskId || "Loading" }
                    </Text>
                  </Skeleton>
                </HStack>
                <Switch>
                  <Case condition={activeStatus === "active"}>
                    <Skeleton
                      as={HStack}
                      isLoaded={tasksState.loading === false}
                      height="1.4rem"
                      flexShrink={1}
                      justifyContent="flex-start"
                      title={`Deadline: ${dayjs(task.deadline).format("DD MMM, YYYY")}`}
                      width="100%"
                    >
                      <Icon as={BiTargetLock} color="gray.600"/>
                      <Text fontSize="smaller">
                        {
                          dayjs(task.deadline)
                            .format(
                              dayjs().isSame(dayjs(task.deadline), "year") ?
                                "DD MMM" :
                                "DD MMM 'YY"
                            )
                        }
                      </Text>
                    </Skeleton>
                  </Case>
                  <Case condition={activeStatus === "pending"}>
                    <HStack spacing={tasksState.loading ? "2" : "0"}>
                      <Skeleton
                        as={HStack}
                        isLoaded={tasksState.loading === false}
                        height="1.4rem"
                        title={`Action date: ${dayjs(task.actionDate).format("DD MMM, YYYY")}`}
                        width="100%"
                      >
                        <Icon as={FiPlay} color="gray.600"/>
                        <Text fontSize="smaller">
                          {
                            dayjs(task.actionDate)
                              .format(
                                dayjs().isSame(dayjs(task.actionDate), "year") ?
                                  "DD MMM" :
                                  "DD MMM 'YY"
                              )
                          }
                        </Text>
                      </Skeleton>
                      <Skeleton
                        as={HStack}
                        isLoaded={tasksState.loading === false}
                        height="1.4rem"
                        title={`Deadline: ${dayjs(task.deadline).format("DD MMM, YYYY")}`}
                        width="100%"
                      >
                        <Icon as={BiTargetLock} color="gray.600"/>
                        <Text fontSize="smaller">
                          {
                            dayjs(task.deadline)
                              .format(
                                dayjs().isSame(dayjs(task.deadline), "year") ?
                                  "DD MMM" :
                                  "DD MMM 'YY"
                              )
                          }
                        </Text>
                      </Skeleton>
                    </HStack>
                  </Case>
                  <Case condition={activeStatus === "closed"}>
                    <Skeleton
                      as={HStack}
                      isLoaded={tasksState.loading === false}
                      height="1.4rem"
                      flexShrink={1}
                      title={`Closed at: ${dayjs(task.lastClosedAt).format("DD MMM, YYYY")}`}
                      width="100%"
                    >
                      <Icon as={FaCheckCircle} color="gray.600"/>
                      <Text fontSize="smaller">
                        {
                          dayjs(task.lastClosedAt)
                            .format(
                              dayjs().isSame(dayjs(task.lastClosedAt), "year") ?
                                "DD MMM" :
                                "DD MMM 'YY"
                            )
                        }
                      </Text>
                    </Skeleton>
                  </Case>
                </Switch>
                <Skeleton
                  as={Box}
                  isLoaded={tasksState.loading === false}
                  width={tasksState.loading ? "50%" : undefined}
                >
                  <Text
                    color="gray.700"
                    fontSize="smaller"
                    title={task.statusToClient}
                  >
                    { task.statusToClient || "Loading" }
                  </Text>
                </Skeleton>
              </Grid>
            ))
          }
          <Center/>
          <When condition={tasksState.error !== null}>
            <Alert
              backgroundColor="red.50"
              borderColor="red.100"
              borderRadius="md"
              borderWidth="thin"
              color="red.600"
              fontSize="sm"
              status="error"
            >
              <Text overflowWrap="anywhere">
                { tasksState.error }
              </Text>
            </Alert>
          </When>
          <When
            condition={
              activeStatus &&
              shouldShowHelpers &&
              tasksState.loading === false &&
              tasksState[activeStatus].items.length === 0
            }
          >
            <Text color="gray.500" padding="4">
              There are no { activeStatus } tasks
            </Text>
          </When>
          {
            (
              activeStatus &&
              tasksState[activeStatus].moreItems &&
              tasksState[activeStatus].items.length >= 20 &&
              tasksState.loading === false
            ) && (
              <Center ref={loadMoreRef} padding="4">
                <Spinner color="brand.500" thickness="3px"/>
              </Center>
            )
          }
        </VStack>
      </VStack>
    </Flex>
  );
};
