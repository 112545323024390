import { firestore } from "firebase-config";

const eventsCollection = firestore.collection("events");

export interface Task {
  actionDate: string;
  actionScheduleDate: string;
  added: string;
  deadline: string;
  id: number;
  isDeleted: number;
  lastClosedAt: string;
  priorityId: 1 | 2 | 3 | 4;
  reminder: string;
  statusId: number;
  statusToClient: string;
  taskHeaderId: number;
  taskId: number;
  taskType: {
    id: number;
    internalUse: number;
    taskTypeName: string;
  };
  title: string;
  updated: string;
}

export interface GetActiveParameters {
  clientId: number;
  startAfter?: {
    priorityId: number;
    deadline: string;
    taskId: number;
  };
}

export interface GetPendingParameters {
  clientId: number;
  startAfter?: {
    priorityId: number;
    deadline: string;
  };
}

export interface GetClosedParameters {
  clientId: number;
  startAfter?: {
    lastClosedAt: string;
  };
}

export interface GetRemindersParameters {
  clientId: number;
  startAfter?: {
    reminder: string;
  };
}

export type GetTasksResponse = Partial<Task>[]

const itemsPerGet = 20;

export const tasksServices = {
  getActive: async (args: GetActiveParameters): Promise<GetTasksResponse> => {
    const query = (
      eventsCollection
        .doc(args.clientId.toString())
        .collection("tasksList")
        .where("statusId", "==", 1)
        .orderBy("priorityId", "asc")
        .orderBy("deadline", "asc")
        .orderBy("taskId", "asc")
    );

    if(args.startAfter) {
      return (
        await query
          .startAfter(
            args.startAfter.priorityId,
            args.startAfter.deadline,
            args.startAfter.taskId
          )
          .limit(itemsPerGet)
          .get()
      ).docs.map(doc => doc.data()) || [];
    }

    return (await query.limit(itemsPerGet).get()).docs.map(doc => doc.data()) || [];
  },
  getClosed: async(args: GetClosedParameters): Promise<GetTasksResponse> => {
    const query = (
      eventsCollection
        .doc(args.clientId.toString())
        .collection("tasksList")
        .where("statusId", "==", 3)
        .orderBy("lastClosedAt", "desc")
    );

    if(args.startAfter) {
      return (
        await query
          .startAfter(args.startAfter.lastClosedAt)
          .limit(itemsPerGet)
          .get()
      ).docs.map(doc => doc.data()) || [];
    }

    return (await query.limit(itemsPerGet).get()).docs.map(doc => doc.data()) || [];
  },
  getPending: async(args: GetPendingParameters): Promise<GetTasksResponse> => {
    const query = (
      eventsCollection
        .doc(args.clientId.toString())
        .collection("tasksList")
        .where("statusId", "==", 2)
        .orderBy("priorityId", "asc")
        .orderBy("deadline", "desc")
    );

    if(args.startAfter) {
      return (
        await query
          .startAfter(
            args.startAfter.priorityId,
            args.startAfter.deadline
          )
          .limit(itemsPerGet)
          .get()
      ).docs.map(doc => doc.data()) || [];
    }

    return (await query.limit(itemsPerGet).get()).docs.map(doc => doc.data()) || [];
  },
  getReminders: async(args: GetRemindersParameters): Promise<GetTasksResponse> => {
    const query = (
      eventsCollection
        .doc(args.clientId.toString())
        .collection("tasksList")
        .where("statusId", "!=", 3)
        .where("taskType.internalUse", "==", 0)
        .where("taskType.id", "==", 44)
        .orderBy("statusId", "asc")
        .orderBy("reminder", "asc")
    );

    if(args.startAfter) {
      return (
        await query
          .startAfter(args.startAfter.reminder)
          .limit(itemsPerGet)
          .get()
      ).docs.map(doc => doc.data()) || [];
    }

    return (await query.limit(itemsPerGet).get()).docs.map(doc => doc.data()) || [];
  },
};
