import {
  action,
  Action,
  actionOn,
  ActionOn,
  persist,
  thunk,
  Thunk,
} from "easy-peasy";
import { AsyncReturnType } from "type-fest";

import { Injections, Store } from "store";
import { BaseModel, createBaseModel } from "store/create-base-model";

export interface Account extends BaseModel<Account> {
  authentication: Partial<{
    accountServiceToken: string;
    authenticated: boolean;
    expiresAt: number;
    refreshToken: string;
    token: string;
  }>;
  clientId: number | null;
  clientDisplayName: string | null;
  clientPhoto: string | null;
  eaEmail: string | null;
  initialized: boolean;
  personTypeId: number | null;

  initialize: Thunk<
  Account,
  undefined,
  Injections,
  Store,
  Promise<string | void>
  >;
  loginWithEmailAndPassword: Thunk<
  Account,
  {
    email: string;
    password: string;
  },
  Injections,
  Store
  >;
  logout: Action<Account>;
  onInitialize: ActionOn<Account>;
  onLoginWithEmailAndPassword: ActionOn<Account>;
}

const resetState: Record<string, boolean | null | Record<string, boolean>> = {
  error: null,
  loading: false,
  authentication: {
    authenticated: false
  },
  clientId: null,
  clientDisplayName: null,
  clientPhoto: null,
  eaEmail: null,
  initialized: true,
  personTypeId: null
};

export const account: Account = persist(
  {
    ...createBaseModel({ action }),

    authentication: {
      authenticated: false
    },
    clientId: null,
    clientDisplayName: null,
    clientPhoto: null,
    eaEmail: null,
    initialized: false,
    personTypeId: null,

    initialize: thunk(async (actions, payload, helpers) => {
      actions.setLoading(false);

      if(helpers.getState().authentication.accountServiceToken) {
        return;
      }

      if(helpers.getState().authentication.expiresAt) {
        // TODO:
      }

      try {
        const { token } = await helpers.injections.services.account.loginToAccountsService();

        return token;
      } catch(error) {
        helpers.fail(error);
      }
    }),
    loginWithEmailAndPassword: thunk(async (actions, payload, helpers) => {
      actions.setError(null);
      actions.setLoading(true);

      try {
        const token = helpers.getState().authentication.accountServiceToken as string;
        const response = await helpers.injections.services.account.loginWithEmailAndPassword(payload, token);

        return response;
      } catch(error) {
        actions.setError(error.message);
        helpers.fail(error.message);
      } finally {
        actions.setLoading(false);
      }
    }),
    logout: action(state => {
      const accountServiceToken = state.authentication.accountServiceToken;

      Object.keys(resetState).map(key => {
        (state as unknown as typeof resetState)[key] = resetState[key];
      });

      state.authentication.accountServiceToken = accountServiceToken;
    }),
    onInitialize: actionOn(
      actions => actions.initialize,
      (state, target) => {
        state.initialized = true;

        if(target.result) {
          state.authentication.accountServiceToken = target.result;
        }
      }
    ),
    onLoginWithEmailAndPassword: actionOn(
      actions => actions.loginWithEmailAndPassword,
      (state, target) => {
        const result = target.result as AsyncReturnType<Injections["services"]["account"]["loginWithEmailAndPassword"]>;

        state.authentication = {
          ...state.authentication,
          authenticated: true,
          expiresAt: (new Date()).setDate((new Date()).getDate() + 1),
          refreshToken: result.data.refreshToken,
          token: result.data.token
        };
        state.clientDisplayName = result.data.clientDisplayName;
        state.clientId = result.data.clientId;
        state.clientPhoto = result.data.clientPhoto;
        state.eaEmail = result.data.eaMail;
        state.personTypeId = result.personTypeId;
      }
    )
  },
  {
    deny: [
      "error",
      "initialized",
      "loading"
    ],
    storage: "localStorage"
  }
);
