import { createStore as createEasyPeasyStore, createTypedHooks } from "easy-peasy";

import { accountServices, tasksServices } from "services";
import { account, Account } from "store/account";
import { tasks, Tasks } from "store/tasks";

import { firestore } from "firebase-config";

export interface Injections {
  firestore: typeof firestore;
  services: {
    account: typeof accountServices;
    tasks: typeof tasksServices;
  };
}

export interface Store {
  account: Account;
  tasks: Tasks;
}

export const createStore = () => createEasyPeasyStore<Store>(
  { account, tasks },
  {
    injections: {
      firestore,
      services: {
        account: accountServices,
        tasks: tasksServices
      }
    }
  }
);

const typedHooks = createTypedHooks<Store>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreState = typedHooks.useStoreState;
