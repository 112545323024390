const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_ACCOUNT_LOCAL : process.env.REACT_APP_ACCOUNT;

export interface LoginWithEmailAndPasswordResponse {
  data: {
    accountId: number;
    allLSW: ({
      [key: string]: {
        anchorTZDisplayname: string;
        endLocalTime: string;
        endUTCTime: string;
        isActive: 0 | 1;
        name: string;
        startLocalTime: string;
        starUTCTime: string;
      };
    });
    clientId: number;
    clientDisplayName: string;
    clientFullName: string;
    clientPhoto: string;
    eaMail: string;
    eaStaffId: string;
    eaDisplayName: string;
    eaFullName: string;
    eaPhoto: string;
    csmMail: string;
    csmStaffId: string;
    csmDisplayName: string;
    csmFullName: string;
    lsw: string[];
    subscriptions: ({
      default: number;
      id: number;
      lsw: string;
      subscription: string;
    })[];
    subscriptionIds: number[];
    token: string;
    refreshToken: string;
  };
  message: string;
  personTypeId: number;
}

export const accountServices = {
  loginToAccountsService: async (): Promise<{ token: string }> => {
    const result = await fetch(
      `${baseUrl}/login`,
      {
        body: new URLSearchParams({
          identity: "ms_20", // We probably have to change this to something like "cwa"
          token: "password"
        }),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }
    ).then(response => {
      if (response.status >= 400 && response.status < 600) {
        throw new Error(response.statusText);
      }

      return response;
    });

    return result.json();
  },
  loginWithEmailAndPassword: (
    async (
      credentials: {
        email: string;
        password: string;
      },
      token: string
    ):
    Promise<LoginWithEmailAndPasswordResponse> => {
      return await fetch(
        `${baseUrl}/v2/loginUser?appVersion=Postman`,
        {
          body: new URLSearchParams({
            username: credentials.email,
            password: credentials.password
          }),
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          method: "POST"
        }
      ).then(async response => {
        const result = await response.json() as unknown as LoginWithEmailAndPasswordResponse;

        if(response.ok === false) {
          throw new Error(result.message);
        }

        // If user is not a client, then reject
        if(result.personTypeId !== 2) {
          throw new Error("Error: Username not found");
        }

        return result;
      });
    }
  )
};
