import React from "react";
import {
  Route as ReactRouterRoute,
  RouteProps as ReactRouterRouteProps,
  Redirect
} from "react-router-dom";

import { useStoreState } from "store";

export interface RouteProps extends ReactRouterRouteProps {
  type?: "login" | "private" | "public";
}

export const Route = (props: RouteProps): JSX.Element => {
  const authenticated = useStoreState(state => state.account.authentication.authenticated);

  if(props.type === "login" && authenticated) {
    return <Redirect to={(new URLSearchParams(props.location?.search)).get("redirect") || "/tasks"}/>;
  }

  if(props.type === "private" && authenticated === false) {
    const to = (
      props.location?.pathname ?
        `/login?redirect=${encodeURIComponent(props.location.pathname)}` :
        "/login"
    );

    return <Redirect to={to}/>;
  }

  if(props.location?.pathname === "/" && authenticated) {
    return <Redirect to="/tasks"/>;
  }

  if(props.location?.pathname === "/" && authenticated === false) {
    return <Redirect to="/login"/>;
  }

  return <ReactRouterRoute {...props}/>;
};
