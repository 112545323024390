import React from "react";
import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input
} from "@chakra-ui/react";
import { When } from "react-if";
import { useLocation } from "react-router";

import { useStoreActions, useStoreState } from "store";

import logo from "assets/logo.png";

export const Login = (): JSX.Element => {
  const [ credentials, setCredentials ] = React.useState({
    email: "",
    password: ""
  });
  const accountActions = useStoreActions(actions => actions.account);
  const accountState = useStoreState(state => state.account);
  const location = useLocation();

  const onSubmit = React.useCallback(async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    await accountActions.loginWithEmailAndPassword(credentials);

    (new URLSearchParams(location.search)).get("redirect");
  }, [credentials, location.search]);

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      padding="4"
      position="relative"
    >
      <Image height="50px" src={logo}/>
      <Flex
        alignItems="stretch"
        as="form"
        flexDirection="column"
        marginTop="10"
        maxWidth="400"
        noValidate
        onSubmit={onSubmit}
        width="100%"
      >
        <When condition={accountState.error !== null}>
          <Alert
            backgroundColor="red.50"
            borderColor="red.100"
            borderRadius="md"
            borderWidth="thin"
            color="red.600"
            fontSize="sm"
            marginBottom="5"
            status="error"
          >
            { accountState.error }
          </Alert>
        </When>
        <FormControl>
          <FormLabel>
            E-mail address
          </FormLabel>
          <Input
            autoComplete="email"
            onChange={event => {
              setCredentials({
                ...credentials,
                email: event.currentTarget.value
              });
            }}
            value={credentials.email}
          />
        </FormControl>
        <FormControl marginTop="2">
          <FormLabel>
            Password
          </FormLabel>
          <Input
            autoComplete="password"
            onChange={event => {
              setCredentials({
                ...credentials,
                password: event.currentTarget.value
              });
            }}
            type="password"
            value={credentials.password}
          />
        </FormControl>
        <Button
          colorScheme="brand"
          disabled={
            credentials.email.length === 0 ||
            credentials.password.length === 0 ||
            accountState.loading
          }
          isLoading={accountState.loading}
          marginTop="5"
          type="submit"
        >
          Log in
        </Button>
      </Flex>
    </Flex>
  );
};
